import { render, staticRenderFns } from "./overViewOfSystem.vue?vue&type=template&id=24f9a44d&scoped=true&"
import script from "./overViewOfSystem.vue?vue&type=script&lang=js&"
export * from "./overViewOfSystem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f9a44d",
  null
  
)

export default component.exports